import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { EnumHelper } from "../../common/EnumHelper";
import ButtonLoader from "../../shared/ButtonLoader";
import { toastError, toastSuccess } from "../../shared/toastHelper";
import ApiKit from "../../utilities/helper/ApiKit";
import Select from "../Input/Select";
import ErrorText from "../Typo/ErrorText";

const initialState = {
  status: "",
};

function StatusChangeModal({ init = () => {}, data = {}, onClose = () => {} }) {
  const [error, setError] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...initialState });

  const validate = (payload) => {
    const errors = { ...initialState };
    let isValid = true;
    if (!payload.status) {
      errors.status = "Status is required";
      isValid = false;
    }
    setError({ ...errors });
    return isValid;
  };

  const dataName = get(data, "employee_name", "");
  const weekEnding = get(data, "week_ending", "");

  const orderStatusOptions = useMemo(() => {
    return EnumHelper({ type: "status", options: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectValue = (options, value) => {
    return options.filter((item) => {
      return item.value.toLowerCase() === value.toLowerCase();
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    const payload = {
      status: formData.status.toLowerCase(),
    };

    if (!validate(payload)) {
      setLoading(false);
      return;
    }

    const onSuccess = (response) => {
      init();
      toastSuccess({
        message: "Status Updated Successfully",
      });
      onClose();
      setFormData({ ...initialState });
      setError({ ...initialState });
    };

    const onError = (error) => {
      const message = get(error, "response.data.message", "");
      toastError({
        message: message ? message : "Status Update Failed",
      });
    };

    const url = ApiKit.timeSheet.putStatusChange(data.id, payload);

    url
      .then(onSuccess)
      .catch(onError)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const roles = get(data, "status", "");
    setFormData({
      ...formData,
      status: roles,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="">
      <h1 className="text-xl font-bold mb-2">
        Change Status of {dataName} for Week Ending {weekEnding}
      </h1>

      <form onSubmit={handleRegister} className="mt-6">
        <label>Status</label>
        <Select
          options={orderStatusOptions}
          value={getSelectValue(orderStatusOptions, formData.status)}
          placeholder="Select Status"
          closeMenuOnSelect
          name="status"
          onChange={({ label, value }) =>
            setFormData({ ...formData, status: value })
          }
        ></Select>
        <ErrorText error={error.status} />
        <div className="mt-4">
          <button
            type="submit"
            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            onClick={handleRegister}
          >
            <div className={`flex items-center justify-center`}>
              {loading ? (
                <ButtonLoader isLoading={loading} />
              ) : (
                <span>Assign</span>
              )}
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

export default StatusChangeModal;
