export const ENUMS = {
  status: {
    pending: {
      value: "pending",
      display: "Pending",
    },
    readyToApprove: {
      value: "ready to approve",
      display: "Ready to Approve",
    },
    approved: {
      value: "approved",
      display: "Approved",
    },
  },
};

const defaultCreateOptions = ({ key, enumType }) => {
  return { label: enumType[key].display, value: enumType[key].value };
};

export const EnumHelper = ({
  type,
  value,
  display = false,
  property = false,
  color = false,
  options = false,
  createOptions,
}) => {
  const enumType = ENUMS[type];
  const allTheKeys = Object.keys(enumType);

  if (type && value !== undefined) {
    const find = allTheKeys.find((key) => enumType[key].value === value);
    if (display) return enumType[find] ? enumType[find].display : "";
    if (color && find) return enumType[find].color;
    if (property) return find;
    return enumType[find];
  }

  if (type && options) {
    const createdOptions = allTheKeys.map((key) => {
      if (createOptions) return createOptions({ key, enumType });
      return defaultCreateOptions({ key, enumType });
    });
    return createdOptions;
  }
};
